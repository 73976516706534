import React from 'react'

const Dashboard = () => {
  return (
    <>
    <div>
  <h2 className='text-center'>Welcome to Vvision Globex Admin Panel !!!!!!!</h2>
</div>
    </>
  )
}

export default Dashboard